<template>
  <div class="admin-container section-navigator load-organisation">
     <div class="admin-logo">
        <img src="../../../src/assets/images/dCarbonLogo.png" />
      </div>
    <h2 class="admin-text">Search for a Client to Load Their Business Units:</h2>

    <div v-if="!$store.state.user.organisationID" class="search-container">
      <autocomplete
        class="search-bar"
        :search="fetchClients"
        :get-result-value="getResultValue"
        @submit="submit"
      ></autocomplete>
      <div v-for="organisation in organisations" :key="organisation.id">
        <div
          class="square-button green admin-btn"
          @click="impersonateOrganisation(organisation.id)"
        >
          Load Inventory for Business Unit: {{ organisation.title }}
        </div>
        </div>
    </div>
    <div v-else>
      <h3 v-if="$store.state.app.organisation" class="secondary-title">
        Inventory currently loaded for: {{ $store.state.app.organisation.title }}
      </h3>
      <div
        v-if="$store.state.app.organisation"
        class="square-button green admin-btn"
        @click="unloadOrganisationSchema"
      >
        Unload Inventory
      </div>
    </div>
  </div>
</template>

<script>
import clientQueries from '../../queries/client.js'

export default {
  data () {
    return {
      input: '',
      client: {},
      organisations: []
    }
  },
  methods: {
    async fetchClients (input) {
      if (input.length < 3) {
        this.client = {}
        this.organisations = []
        return []
      }
      this.loading = true
      try {
        const response = await this.$apollo.query({
          query: clientQueries.READ_CLIENTS_QUERY,
          variables: {
            title: input
          }
        })
        // console.log(response)
        return response.data.readClients.nodes
      } catch (e) {
        this.error = e
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    async fetchOrganisations () {
      this.loading = true
      try {
        const response = await this.$apollo.query({
          query: clientQueries.READ_CLIENT_ORGANISATIONS,
          variables: {
            clientID: this.client.id
          }
        })
        // console.log(response)
        this.organisations = response.data.readOrganisations.nodes
      } catch (e) {
        this.error = e
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    submit (result) {
      this.client = result
      this.fetchOrganisations()
    },
    getResultValue (result) {
      return result.title
    },
    async impersonateOrganisation (id) {
      await this.$store.dispatch('updateUser', { impersonateClientID: this.client.id, organisationID: id })
      await this.$store.dispatch('loadAppData')
      await this.$root.updateSessionLocale(this.$store.state.app.selectedYear)
      if (this.$store.state.app.client.period.nodes.length) await this.$store.dispatch('saveMemberSession', this.$store.state.app.client.period.nodes[0].id)
      this.$root.loadData()
    },
    async unloadOrganisationSchema () {
      await this.$store.dispatch('updateUser', { clientID: 0, impersonateClientID: 0, organisationID: 0 })
      await this.$store.dispatch('loadAppData')
      if (this.$store.state.app.client.period.nodes.length) await this.$store.dispatch('saveMemberSession', this.$store.state.app.client.period.nodes[0].id)

      this.$root.masterSchema = this.$root.defaultSchema
      this.$root.fuelEnergyTotal = 0
      this.organisations = []
    }
  }
}
</script>

<style>
</style>
